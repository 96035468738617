<template>
	<div class="app-main__inner">
		<faq-component :key="new Date().getHours()"/>
	</div>
</template>

<script>
import FaqComponent from "../Components/FaqComponent";

export default {
	name: "Faq",
	components: {
		FaqComponent
	}
}
</script>

<style scoped>

</style>
