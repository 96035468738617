<template>
	<div class="bg-white mb-1 rounded overflow-hidden"><!--v-wave -->
		<div class="card-header text-xs cursor p-4 color-custom-head" id="headingOne2" role="tab"
		>
			<div @click="openAccordion">
                <span v-if="active">
                    <svg class="h-4 w-4 mr-2 collapse-icon font-semibold" xmlns="http://www.w3.org/2000/svg" fill="none"
						 viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7"/>
                    </svg>
                </span>
				<span v-else>
                    <svg class="h-4 w-4 mr-2 collapse-icon font-semibold" xmlns="http://www.w3.org/2000/svg" fill="none"
						 viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
                    </svg>
                </span>
			</div>
			<div class="w-full flex justify-between">
				<p class="w-full" @click="openAccordion">{{ data.question }}</p>
				<span v-if="userType === 'admin'" class="w-10" v-on:click="onEditFaq">
				<i class="text-base cursor-pointer iconly-brokenEdit icon-color"></i>
			</span>
			</div>
		</div>
		<transition name="slide">
			<div v-if="active">
				<div class="mx-3 b-top">
				</div>
				<div class="card-body border-theme-color body-accordion space-y-3 text-xs font-light"
					 v-html="data.answer"></div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	name: 'accordion-faq',
	props: {
		data: {
			type: Object,
			required: false
		},
		userType: {
			default: 'normal',

		}
	},
	data() {
		return {
			active: false,
			// baseURL : window.location.origin,
		}
	},
	methods: {
		openAccordion() {
			this.active = !this.active
		},
		onEditFaq: function () {
			window.Bus.$emit('open-faq-edit', this.data)
		}

	}
}
</script>

<style lang="scss" scoped>
@import "./../assets/variables.scss";

.cc-color {
	color: #736f6f !important;
}

.slide-enter-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: ease-in;
	-webkit-transition-timing-function: ease-in;
	-o-transition-timing-function: ease-in;
	transition-timing-function: ease-in;
}

.slide-leave-active {
	-moz-transition-duration: 0.3s;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
	-moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	-o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
	max-height: 100px;
	overflow: hidden;
}

.slide-enter, .slide-leave-to {
	overflow: hidden;
	max-height: 0;
}

.rounded {
	border-radius: 0.8rem !important;
}

.collapse-icon {
	color: $theme-primary-color !important;
}

.border-theme-color {
	border-color: $form-border !important;
}

.card-header {
	border-bottom: unset !important;
}

.b-top {
	border-top: 1px solid $form-border !important;
}
</style>
