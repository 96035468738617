<template>
	<div class="app-main__inner">
		<div class="container-fluid mb-3">
			<div class="row">
				<div class="col-md-12 mb-3">
					<h2 class="font-semibold font-size-lg">{{ $t('accountFirmPayout') }}</h2>
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div class="bg-white mb-3 h-12">
						<div class="float-right margin-right-12 d-flex">
							<div class="input-icon-position mr-2">
								<span @click="showFilter = !showFilter"
									  class="flex justify-between align-items-center space-x-4 bg-gray-200 border px-3 py-2 rounded-md">
                                <span><img src="./../../assets/images/filter.png" alt="" class="filter-icon"></span>
                                <span class="text-gray-500">{{ $t('filter') }}</span>
                            		</span>
								<div class="absolute inset-0 z-40" @click="showFilter = false"
									 v-if="showFilter"></div>
								<div class="absolute w-60 bg-white rounded border shadow-lg md:-ml-20"
									 style="z-index:9999"
									 v-if="showFilter">
									<kick-back-payout-filter/>
								</div>
							</div>
							<div class="input-icons input-icon-position">
								<i class="iconly-brokenSearch"></i>
								<input type="text" @input="searchFilter" v-model="searchName"
									   class="text-sm h-10 form-control custom-search"
									   v-bind:placeholder="$t('search')">
							</div>
						</div>
					</div>
				</div>
			</div>
			<table style="width: 100%;" id="exampledestroy"
				   class="table table-hover table-striped table-bordered"
			>
				<thead>
				<tr>
					<th class="text-xs font-bold"><span class="iconly-boldProfile">{{ $t('serial') }}</span></th>
					<th class="text-xs text-gray-800 font-bold">
						<div class="d-flex justify-content-between">{{ $t('company') }}<span><i
							class="lnr-arrow-up cursor-pointer" v-on:click="sortAscCompanyBy('accounting_firm')"></i><i
							class="lnr-arrow-down cursor-pointer" v-on:click="sortDescCompanyBy('accounting_firm')"></i></span>
						</div>
					</th>
					<th class="text-xs text-gray-800 font-bold">{{ $t('invoiceNumber') }}</th>
					<th class="text-xs text-gray-800 font-bold">{{ $t('amount_') }}</th>
					<th class="text-xs text-gray-800 font-bold">{{ $t('status') }}</th>
					<th class="text-xs text-gray-800 font-bold">
						<div class="d-flex justify-content-between">{{ $t('invoiceDate') }}
							<span><i
								class="lnr-arrow-up cursor-pointer"></i><i
								class="lnr-arrow-down cursor-pointer"></i></span>
						</div>
					<th class="text-xs text-gray-800 font-bold">
						<div class="d-flex justify-content-between">{{ $t('invoicePayDate') }}
							<span><i
								class="lnr-arrow-up cursor-pointer"></i><i
								class="lnr-arrow-down cursor-pointer"></i></span>
						</div>
					</th>
					<!--					<th class="text-xs text-gray-800 font-bold">{{ $t('management') }}</th>-->
				</tr>
				</thead>
				<tbody v-loading='vLoadingFirm'>
				<tr v-for="(item, i) in GET_PAYOUT.results" :key="i">
					<td class="house-theme-sm-head">{{ i + 1 }}</td>
					<td class="text-xs font-light">
						<span v-if="item.accounting_firm">
							{{ item.accounting_firm.name }}
						</span>
						<span v-else> --- </span>
					</td>
					<td class="text-xs font-light">
						<span>{{ item.accounting_invoice_number }}</span>
					</td>
					<td class="text-xs font-light">
						<span>{{ item.invoice_amount }}SEK</span>
					</td>
					<td class="text-xs font-light">
						<span class="badge badge-success" v-if="item.payout_status === 0"> {{ $t('paid') }}</span>
						<span class="badge badge-warning" v-else> {{ $t('pending') }}</span>
					</td>
					<td class="text-xs font-light">
						<span>{{ item.invoice_date }}</span>
					</td>
					<td class="text-xs font-light">
						<span>{{ item.invoice_paid_date }}</span>
					</td>
				</tr>
				</tbody>
			</table>
			<div class="float-right mb-5" v-if="GET_PAYOUT.results">
				<paginate
					:page-count="total_pages"
					:click-handler="changePageNumber"
					prev-text="<"
					next-text=">"
					:container-class="'pagination'">
				</paginate>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex"
import Paginate from 'vuejs-paginate'
import debounce from 'lodash/debounce'
import KickBackPayoutFilter from "./components/KickBackPayoutFilter";
import {getFirm} from "../../services/storage-window";

export default {
	name: "Payout",
	components: {
		paginate: Paginate,
		KickBackPayoutFilter
	},
	data: function () {
		return {
			vLoadingFirm: false,
			showFilter: false,
			total_pages: 1,
			limit: 20,
			searchName: ''
		}
	},
	computed: {
		...mapGetters({
			GET_PAYOUT: 'master_accountant/GET_FIRM_PAYOUT',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_PAYOUT_FILTER: 'master_accountant/GET_FIRM_PAYOUT_FILTER',
			GET_USER: 'customer/GET_USER',
		})
	},
	mounted() {
		this.getAuthUser()
		this.getPayout()
		window.Bus.$on('close-payout-filter', e => {
			this.showFilter = false
		})
		window.Bus.$on('filter-payout', e => {
			this.filterPayout()
		})
	},
	methods: {
		getLocalUserId() {
			if (getFirm()) {
				return JSON.parse(getFirm()).manager_id
			}
			return null

		},
		getAuthUser() {
			const URL = this.$services.endpoints.GET_USER
			this.$store.dispatch('customer/getAuthUser', {URL})
				.then(res => {
				}).catch(err => {
				// console.log(err)
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getAuthUser()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out-accountant')
							})
					}
				}
			})
		},
		searchFilter: debounce(function () {
			let url = `?limit=${this.limit}&accounting_firm__affiliate_type=1`
			if (this.searchName !== '') {
				url += `&search=${this.searchName}`
			}
			if (this.getLocalUserId()) {
				url += `&accounting_firm__primary_accountant__id=${this.getLocalUserId()}`
			} else {
				url += `&accounting_firm__primary_accountant__id=${this.GET_USER.id}`
			}
			this.$store.dispatch('master_accountant/getFirmPayout', {data: url}).then(response => {
				if (response.data.data.count > this.limit) {
					this.total_pages = Math.ceil(response.data.data.count / this.limit)
				}
			})
		}, 500),
		changePageNumber: function (num) {
			let url = `?limit=${this.limit}&page=${num}`
			if (this.searchName !== '') {
				url += `&search=${this.searchName}`
			}
			if (this.getLocalUserId()) {
				url += `&accounting_firm__primary_accountant__id=${this.getLocalUserId()}`
			} else {
				url += `&accounting_firm__primary_accountant__id=${this.GET_USER.id}`
			}
			this.$store.dispatch('master_accountant/getFirmPayout', {data: url}).then(response => {
				if (response.data.data.count > this.limit) {
					this.total_pages = Math.ceil(response.data.data.count / this.limit)
				}
			})
		},
		getPayout: function () {
			let URL = `?limit=${this.limit}&accounting_firm__affiliate_type=1`
			if (this.getLocalUserId()) {
				URL += `&accounting_firm__primary_accountant__id=${this.getLocalUserId()}`
			} else {
				URL += `&accounting_firm__primary_accountant__id=${this.GET_USER.id}`
			}
			this.$store.dispatch('master_accountant/getFirmPayout', {data: URL}).then(response => {
				if (response.data.data.count > this.limit) {
					this.total_pages = Math.ceil(response.data.data.count / this.limit)
				}
			}).catch(err => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getPayout()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		filterPayout: function () {
			let url = `?limit=${this.limit}&accounting_firm__affiliate_type=1`
			if (this.searchName !== '') {
				url += `&search=${this.searchName}`
			}
			if (this.getLocalUserId()) {
				url += `&accounting_firm__primary_accountant__id=${this.getLocalUserId()}`
			} else {
				url += `&accounting_firm__primary_accountant__id=${this.GET_USER.id}`
			}
			if (this.GET_PAYOUT_FILTER.length > 0) {
				this.GET_PAYOUT_FILTER.map(item => {
					if (['payout_status'].includes(item.mode)) {
						url += `&${item.mode}=${item.value}`
					} else if (['invoice_date'].includes(item.mode)) {
						if (item.value.range === 'equal') {
							url += `&${item.mode}=${item.value.value}`
						} else {
							if (item.value.date_range && Array.isArray(item.value.date_range)) {
								if (item.value.date_range.length > 1) {
									url += `&${item.mode}_from=${item.value.date_range[0]}&${item.mode}_to=${item.value.date_range[1]}`
								}
							}
						}
					}
				})
			}
			this.$store.dispatch('master_accountant/getFirmPayout', {data: url}).then(response => {
				if (response.data.data.count > this.limit) {
					this.total_pages = Math.ceil(response.data.data.count / this.limit)
				}
			}).catch(err => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.filterPayout()
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		sortAscCompanyBy(item) {
			this.$store.commit('master_accountant/SORT_ASC', {
				'item': item,
				'state': 'payout',
				'copy': 'payoutCopy'
			})
		},
		sortDescCompanyBy(item) {
			this.$store.commit('master_accountant/SORT_DESC', {
				'item': item,
				'state': 'payout',
				'copy': 'payoutCopy'
			})
		},

		ascendingCompanyName() {
			this.$store.commit('house/ASCENDING_ORDER_NAME')
		},
		descendingCompanyName() {
			this.$store.commit('house/DESCENDING_ORDER_NAME')
		},
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.table-bordered thead th, .table-bordered thead td {
	background: #fff;
	border: 1px solid #ffffff;
}

.input-icons i {
	position: absolute;
	margin: 7px;
	top: 5px;
}

.input-icons {
	width: 100%;
	margin-bottom: 10px;
}

.custom-search {
	padding-left: 1.5rem !important;
}

.margin-right-12 {
	margin-right: 12px;
}

.input-icon-position {
	position: relative;
	top: 4px;
}

.form-control {
	border: 1px solid $form-border !important;
	// height: unset !important;
	padding: .175rem .75rem;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.filter-icon {
	height: 15px;
	width: 48px;
}
</style>
