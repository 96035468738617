<template>
    <div class="app-main__inner">
        <div class="container mb-3">
            <div class="row">
                <div class="flex justify-center py-10 col-md-12 bg-blue-600">
                    <h1 class="text-white">Support</h1>
                </div>
                <div class="flex flex-col col-md-12 bg-white md:px-42 mb-7">
                    <div class="row mb-20" v-if="tab === 1">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                            <div class="text-center py-5">
                                <h4 class="font-black">In order for us to help you in the best and most efficient manner, please complete <br> the form and provide detailed information</h4>
                            </div>
                            <div class="mt-2 mb-10 row">
                                <div class="col-md-6 mb-4">
                                    <h5 class="font-black">What is the company's customer Number?</h5>
                                    <small>You will find your customer number on your invoice from Connectivo AB</small>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <h5 class="font-black">Which app is the question regarding?</h5>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <h5 class="font-black">Your contact email</h5>
                                    <small>
                                        If you would like to cc other persons in the support email also,
                                        like your accountant, add their email here also.
                                    </small>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <h5 class="font-black">Your contact phone number</h5>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <h5 class="font-black">Your name</h5>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="flex justify-between">
                                <button class="btn focus:outline-none px-3 py-2 rounded-sm text-white purple">Back</button>
                                <button @click="tab = 2" class="btn focus:outline-none px-3 py-2 text-white rounded-sm test-btn">Next</button>
                            </div>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                    <div class="row mb-20" v-if="tab === 2">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                            <div class="text-center py-5">
                                <h4 class="font-black">Give some insight in what the issue is</h4>
                            </div>
                            <div class="mt-2 mb-10 row">
                                <div class="col-md-6 mb-4">
                                    <h5 class="font-black">What type of issue is it?</h5>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <h5 class="font-black">Describe the issue as detailed as possible</h5>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <div class="form-group">
                                        <textarea class="form-control"></textarea>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <h5 class="font-black">
                                        Give 3 examples by providing the
                                        accounting order/invoice/voucher number
                                    </h5>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control">
                                    </div>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <h5 class="font-black">Attach Screenshot or files for better clarity</h5>
                                </div>
                                <div class="col-md-6 mb-4">
                                    <div class="form-group space-x-3">
                                        <label :class="fileAdded ? 'bg-gray-400 px-2 py-1 rounded cursor-pointer' : 'custom-file-upload'">
                                            <input type="file" class="inputfile form-control-file" id="swish-file-upload" required accept="png/jpg,jpeg">
                                            <i class="iconly-brokenUpload"></i> {{ $t('chooseFile') }}
                                        </label>
                                        <small>png, jpg, jpeg or pdf only</small>
                                    </div>
                                </div>
                            </div>
                            <div class="flex justify-between">
                                <button @click="tab = 1" class="btn focus:outline-none px-3 py-2 rounded-sm text-white purple">Back</button>
                                <button @click="tab = 3" class="btn focus:outline-none px-3 py-2 text-white rounded-sm test-btn">Next</button>
                            </div>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                    <div class="row mb-20" v-if="tab === 3">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                            <div class="text-center py-5">
                                <h4 class="font-black">You're almost done</h4>
                            </div>
                            <p class="text-center mb-10">
                                Our support team will investigate the matter and assist you on the matter shortly. <br>
                                If you are not on a SLA contract you will receive an response within <br>
                                2-3 working days.
                            </p>
                            <p class="text-center mb-10">
                                Note that if the issue was not related or caused by Automatisera <br>
                                Mera connector, you will be billed for 1hr of troubleshooting <br>
                                time to a total cost of 900:- ex vat. 
                            </p>
                            <div class="flex justify-between">
                                <button @click="tab = 1" class="btn focus:outline-none px-3 py-2 rounded-sm text-white purple">Cancel my ticket</button>
                                <button @click="tab = 4" class="btn focus:outline-none px-3 py-2 text-white rounded-sm test-btn">Yes, understands </button>
                            </div>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                    <div class="row mb-20" v-if="tab === 4">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                            <div class="text-center py-5">
                                <h4 class="font-black">Thanks for your submission!</h4>
                            </div>
                            <p class="text-center mb-10">
                                Our support team will investigate the matter and assist you on the matter shortly. <br>
                                If you are not on a SLA contract you will receive an response within <br>
                                2-3 working days.
                            </p>
                            <p class="text-center">
                                 Note that creating additional tickets on the same issue will not <br> speed up the ticket handling. 
                            </p>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                    <div class="row mb-20" v-if="tab === 5">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                            <div class="text-center py-5">
                                <h4 class="font-black">
                                    The examples you have provided are accounting posts  <br>
                                    which are not processed by Automatisera Mera <br>
                                    connectors. You can hover over the man icon <br>
                                    inside the voucher/order/invoice in the accounting program <br>
                                    to see which user has created the post. <br>
                                </h4>
                            </div>
                            <div class="flex justify-center">
                                <div class="w-1/2 rounded-md bg-gray-500 p-20">

                                </div>
                            </div>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'


    export default {
        name : 'support-center',
        data () {
            return {
                tab : 1,
                fileAdded : false
            }
        },
        computed : {
            ...mapGetters({
                GET_LOCALE_LANG : 'utility/GET_LOCALE_LANG',
            })
        },
        mounted () {
            
        },
        methods : {
            
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";
.sticky-table{
    position: -webkit-sticky;
    position: sticky;
    top: 61px;
    background-color: #fff;
    // background-color: #e8eaec;
    height: 50px;
    padding: 7px;
}
.card-body{
    padding: 0 0.9rem !important;
}
.app-main .app-main__inner{
    padding-top: 20px !important;
}
.body-tabs-shadow .body-tabs-animated{
    padding: unset !important;
}
.tab-btn.active{
    box-shadow: unset !important;
    border: 1px solid $theme-secondary-color !important;
    font-weight: 800;
    color: $theme-secondary-color !important;
    border-radius: 5px;
}
.tab-btn{
    color: $theme-secondary-light;
}
.remove-bg{
    background: unset !important;
    background-color: unset !important;
}
.test-btn{
    background-color: #EE4D81;
}
.purple{
    background-color: #9B51E0;
}
input[type="file"] {
    display: none;
}
.custom-file-upload {
    // border: 1px solid #ccc;
    color: white;
    border-radius: 5px;
    background-color: $theme-primary-color;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}
</style>