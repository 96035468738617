<template>
  <div class="bg-gray-100 rounded overflow">
    <div class="flex justify-between align-items-center p-2">
      <button @click="clearDataField('clear')" class="bg-white text-xs rounded border shadow-sm p-1">{{
          $t('clear')
        }}
      </button>
      <small class="text-xs">{{ $t('filter') }}</small>
      <button @click="filterDataNow" class="bg-blue-400 text-xs text-white rounded border shadow-sm p-1">{{
          $t('done')
        }}
      </button>
    </div>
    <div class="flex flex-col">
      <div>
        <div class="bg-white border-b">
          <label class="inline-flex align-items-center item-center mt-1 ml-3">
            <input type="checkbox" class="form-radio cursor-pointer" v-model="filterData.payout_status"
                   value="product-image-filter"/>
            <span class="ml-2 cursor-pointer text-xs">{{ $t('payoutStatus') }} </span>
          </label>
        </div>
        <div v-if="filterData.payout_status === true" class="p-2">
          <div class="form-group">
            <select v-model="filterValue.payout_status" class="form-select w-full h-8">
              <option selected disabled>---</option>
              <option value='0'>{{ $t('paid') }}</option>
              <option value='1'>{{ $t('pending') }}</option>
            </select>
          </div>
        </div>
      </div>
      <div>
        <div class="bg-white border-b">
          <label class="inline-flex align-items-center item-center mt-1 ml-3">
            <input type="checkbox" class="form-radio cursor-pointer" v-model="filterData.invoice_date"
            />
            <span class="ml-2 cursor-pointer text-xs">{{ $t('invoiceDate') }}</span>
          </label>
        </div>
        <div v-if="filterData.invoice_date === true" class="p-2">
          <div class="form-group">
            <select v-model="invoiceDate.range"
                    class="flex form-select justify-between align-items-center h-8 space-x-4 focus:outline-none border px-3 py-2 rounded-md text-gray-500 w-full">
              <option selected disabled>---</option>
              <option value='equal'>{{ $t('date') }}</option>
              <option value='range'>{{ $t('dateRange') }}</option>
            </select>
          </div>
          <div class="form-group">
            <div class="flex justify-between" v-if="invoiceDate.range === 'range'">
              <el-date-picker
                  class="w-1/2 h-8"
                  size="small"
                  v-model="invoiceDate.date_range"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  range-separator="|">
              </el-date-picker>
            </div>
            <div v-else>
              <el-date-picker
                  v-model="invoiceDate.value"
                  type="date"
                  size="small"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  class="h-8"
                  placeholder="Pick a date">
              </el-date-picker>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import Vue from "vue"
import { DatePicker } from 'element-ui'
Vue.use(DatePicker)
export default {
  name: "KickBackPayoutFilter",
  data: function () {
    return {
      type: '',
      filterData: {
        payout_status: false,
        invoice_date: false,
      },
      filterValue: {
        payout_status: '',
      },
      invoiceDate: {
        range: 'equal',
        value: 0,
        date_range: 0,
      }
    }
  },
  computed: {
    ...mapGetters({
      GET_PAYOUT_FILTER: 'master_accountant/GET_FIRM_PAYOUT_FILTER'
    })
  },
  methods: {
    clearDataField: function () {
      this.filterData = {
        payout_status: false,
        invoice_date: false,
      }
      for (const [key, value] of Object.entries(this.filterData)) {
        this.$store.commit('master_accountant/REMOVE_PAYOUT_FILTER', {mode: key})
      }
      window.Bus.$emit('filter-payout')
      window.Bus.$emit('close-payout-filter')
    },
    filterDataNow: function () {
      let payload = {
        payout_status: this.filterValue.payout_status,
        invoice_date: this.invoiceDate
      }
      for (const [key, value] of Object.entries(this.filterData)) {
        if (value === true) {
          if (['payout_status'].includes(key)) {
            if (this.filterValue[key] !== '') {
              this.$store.commit('master_accountant/UPDATE_PAYOUT_FILTER', {
                mode: key,
                value: payload[key]
              })
            }
          } else {
            this.$store.commit('master_accountant/UPDATE_PAYOUT_FILTER', {
              mode: key,
              value: payload[key]
            })
          }

        } else {
          let modeExist = this.GET_PAYOUT_FILTER.filter(item => item.mode === key)
          if (modeExist.length > 0) {
            this.$store.commit('master_accountant/REMOVE_PAYOUT_FILTER', {mode: key})
          }
        }
      }
      window.Bus.$emit('filter-payout')
    }
  }
}
</script>

<style scoped>
.form-select {
  font-size: 12px;
}
</style>
